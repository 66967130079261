import React, { useState, useEffect } from 'react';
import GallerySection from './gallerydiv';
  
const Ogb_Gallery = () => {

  return (
    <div class="mainContent">
      <h1>Gallery</h1>
      <GallerySection year="2008" imageNum={1}></GallerySection>
      <GallerySection year="2009" imageNum={1}></GallerySection>
      <GallerySection year="2011" imageNum={3}></GallerySection>
      <GallerySection year="2012" imageNum={3}></GallerySection>
      <GallerySection year="2014" imageNum={2}></GallerySection>
      <GallerySection year="2016" imageNum={3}></GallerySection>
      <GallerySection year="2018" imageNum={3}></GallerySection>
      <GallerySection year="2019" imageNum={2}></GallerySection>
      <GallerySection year="2020" imageNum={3}></GallerySection>
      <GallerySection year="2021" imageNum={3}></GallerySection>
      <GallerySection year="2022" imageNum={3}></GallerySection>
      <GallerySection year="2023" imageNum={6}></GallerySection>
      <GallerySection year="2024" imageNum={6}></GallerySection>

    </div>
  );
};
  
export default Ogb_Gallery;