import React, { useState } from "react";
import { useEffect } from "react";
import { db } from "../firebase-config";
import { collection, getDocs, updateDoc, doc, where, query, orderBy } from "firebase/firestore"
import moment from "moment";
  
const GolfPass = () => {

  const [pass, setPass] = useState([]);
  const golfCollectionRef = collection(db, "GolfPass");
  const usersCollectionRef = collection(db, "users")
  const [usersList, setUsersList] = useState([]);
  let eventTime;
  const startOfDay = new Date();
  startOfDay.setUTCHours(0,0,0,0);
  const [inputName, setInputName] = useState("");
  let nameCheck="";
  let nameSwitch=false;


  
  useEffect(() =>{

    const getPass = async () =>{
      const q = query(golfCollectionRef);
      const newData = await getDocs(q);
      setPass(newData.docs.map((doc) =>({...doc.data(), id: doc.id})));
    }; 

    getPass().then(function(){
      console.log("get events function done")
    }); 

  }, []);

  useEffect(() =>{
    const getUsers = async () => {
      const qw = query(usersCollectionRef, orderBy("name", "asc"));
      const dbUsers = await getDocs(qw);
      setUsersList(dbUsers.docs.map((doc) =>({...doc.data()})))
    }

    getUsers();
  }, [])

  const checkForAdd = async (id, index) =>{
    let tempData=[];
    const getPass = async () =>{
      const q = query(golfCollectionRef);
      const newData = await getDocs(q);
      setPass(newData.docs.map((doc) =>({...doc.data(), id: doc.id})));
      tempData=newData.docs.map((doc) =>({...doc.data(), id: doc.id}));
      addParticipant(id, tempData[index].participants, index)
    }; 
    getPass()
  }

  const checkForWaiting = async (id, index) =>{
    let tempData=[];
    const getPass = async () =>{
      const q = query(golfCollectionRef);
      const newData = await getDocs(q);
      setPass(newData.docs.map((doc) =>({...doc.data(), id: doc.id})));
      tempData=newData.docs.map((doc) =>({...doc.data(), id: doc.id}));
      addWaitingList(id, tempData[index].participants, tempData[index].Waiting)
    }; 
    getPass()
  }

  const addWaitingList = async (id, Attendees, Waiting) =>{
    const golfDoc = doc(db, "GolfPass", id)
    let shownName=""
    if (Attendees.length)
    if(inputName === ""){
      console.log("Error, no Input");
      document.getElementById(id+"text").style.display = "block";
    }else{
      for(let i=0; i<usersList.length; i++){
        nameCheck = usersList[i].email;
        if(nameCheck === inputName.toLowerCase()){
          console.log("yes, name is " + usersList[i].name);
          nameSwitch=true;
          shownName= usersList[i].name;
        }
      }
      if(!Attendees.includes(shownName) && !Waiting.includes(shownName)){
        if(nameSwitch===true){
          const newWaiting = {Waiting: [...Waiting, shownName]}
          await updateDoc(golfDoc, newWaiting);
          console.log("no error")
          document.getElementById(id+"text").style.display = "none";
          document.getElementById(id+"error").style.display = "none";
          document.getElementById(id+"duplicate").style.display = "none";
          document.getElementById(id+"success").style.display = "block";
          document.getElementById(id+"input").value = "";
          const getPass = async () =>{
            const q = query(golfCollectionRef);
            const newData = await getDocs(q);
            setPass(newData.docs.map((doc) =>({...doc.data(), id: doc.id})));
          };
          setInputName("");
          getPass();
        }else{
          console.log("nameSwitch is false")
          document.getElementById(id+"error").style.display = "block";
        }
      }else{
        document.getElementById(id+"duplicate").style.display = "block";
      }
    }
  }


  const addParticipant = async (id, participants, index) =>{
    const golfDoc = doc(db, "GolfPass", id)
    let shownName=""
      if(inputName === ""){
        console.log("Error, no Input");
        document.getElementById(id+"text").style.display = "block";
      }else{
        for(let i=0; i<usersList.length; i++){
          nameCheck = usersList[i].email;
          if(nameCheck === inputName.toLowerCase()){
            console.log("yes, name is " + usersList[i].name);
            nameSwitch=true;
            shownName= usersList[i].name;
          }
        }
        if(!participants.includes(shownName)){
          if(nameSwitch===true){
            const newAttendees = {participants: [...participants, shownName]}
            await updateDoc(golfDoc, newAttendees);
            console.log("no error")
            document.getElementById(id+"text").style.display = "none";
            document.getElementById(id+"error").style.display = "none";
            document.getElementById(id+"duplicate").style.display = "none";
            document.getElementById(id+"success").style.display = "block";
            document.getElementById(id+"input").value = "";
            const getEvents = async () =>{
              const q = query(golfCollectionRef);
              const newData = await getDocs(q);
              setPass(newData.docs.map((doc) =>({...doc.data(), id: doc.id})));
            };
            setInputName("");
            getEvents();
  
          }else{
            console.log("nameSwitch is false")
            document.getElementById(id+"error").style.display = "block";
          }
        }else{
          document.getElementById(id+"duplicate").style.display = "block";
        }
      }
    }


  return (
    <div class="mainContent">
      <h1>TeeTime Golf Pass 2025 Sign-Up</h1>
      <h4>TeeTime Golf Pass has offered our group a 26% discount for mbgolfers that purchase a 2025 pass prior to 31 December 2024.<br></br><br></br>
      Sign-up below if you would like to purchase a 2025 pass and I’ll email the discount code and link for your
      purchase. The Midwest Pass will be $48 (vs. the list price of $65).<br></br><br></br>
      Please call or text Mike Baumgartner with any questions.</h4>
      <ul class="eventList">
        {pass?.map((event, index) => (
            <li key={event.id}>
              <div class="eventDiv">
                <div class="eventHeader">
                </div>
                <div class="eventHeader" style={{justifyContent: 'spaceBetween'}}>
                    {/* <h4>
                        {new Date(event.date.seconds * 1000 + event.date.nanoseconds / 1000000).toDateString()}<br></br>
                        {eventTime = event.date.toDate().toLocaleTimeString('en-US', { timeZone: 'America/New_York' })}
                    </h4> */}
                </div>
                <div class="eventContent">
                  <section>
                    <h2>Signed Up:</h2>
                    <ol>
                      {event.participants.map((attendee, index)=>(
                        <li key={index}>
                          <p>{attendee}</p>
                        </li>
                      ))}
                    </ol>
                  </section>
                  <div class="attendeeDiv">
                    <section>
                      <p class="attendeeInfo">Number Signed Up: {event.participants.length}</p>
                    </section>
                    <section>
                        <div class="inputDiv">
                            <input id={event.id+'input'} class="attendeeInput" placeholder="Email Address" onChange={(e) =>{setInputName(e.target.value)}}/>
                            <h3 id={event.id+'text'} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>Please Enter a Name</h3>
                            <h3 id={event.id+'duplicate'} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>Name is already signed up</h3>
                            <h5 id={event.id+'error'} style={{display: 'none', margin: '0px', marginBottom: '10px', color: 'red'}}>The Email entered was not recognized. <br></br>
                            If this was a mistake, contact Mike Baumgartner.</h5>
                            <button id={event.id} onClick={() =>{checkForAdd(event.id, index)}}>Sign Up</button>
                            <h3 id={event.id+'success'} style={{display: 'none', margin: '0px', marginTop: '10px', color: 'blue'}}>Submission Successful!</h3>
                            <h3 id={event.id+'checkFail'} style={{display: 'none', margin: '0px', marginTop: '10px', color: 'blue'}}>This spot has already been filled</h3>
                        </div>
                    </section>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>

    </div>
  );
};
  
export default GolfPass;