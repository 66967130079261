import React, { useState, useEffect } from "react";
import Group2008p1 from './galleryImages/Group2008.jpg';
import Group2009p1 from './galleryImages/Group2009p1.jpg';
import Group2009p2 from './galleryImages/Group2009p2.jpg';
import Group2011p1 from './galleryImages/Group2011p1.jpg';
import Group2011p2 from './galleryImages/Group2011p2.jpg';
import Group2011p3 from './galleryImages/Group2011p3.jpg';
import Group2012p1 from'./galleryImages/Group2012p1.jpg';
import Group2012p2 from'./galleryImages/Group2012p2.jpg';
import Group2012p3 from'./galleryImages/Group2012p3.jpg';
import Group2014p1 from'./galleryImages/Group2014p1.jpg';
import Group2014p2 from'./galleryImages/Group2014p2.jpg';
import Group2016p1 from'./galleryImages/Group2016p1.jpg';
import Group2016p2 from'./galleryImages/Group2016p2.jpg';
import Group2016p3 from'./galleryImages/Group2016p3.jpg';
import Group2018p1 from'./galleryImages/Group2018p1.jpg';
import Group2018p2 from'./galleryImages/Group2018p2.jpg';
import Group2018p3 from'./galleryImages/Group2018p3.jpg';
import Group2019p1 from'./galleryImages/Group2019p1.jpg';
import Group2019p2 from'./galleryImages/Group2019p2.jpg';
import Group2020p1 from'./galleryImages/Group2020p1.jpg';
import Group2020p3 from'./galleryImages/Group2020p3.jpg';
import Group2020p4 from'./galleryImages/Group2020p4.jpg';
import Group2021p1 from'./galleryImages/Group2021p1.jpg';
import Group2021p2 from'./galleryImages/Group2021p2.jpg';
import Group2021p3 from'./galleryImages/Group2021p3.jpg';
import Group2022p1 from'./galleryImages/Group2022p1.jpg';
import Group2022p2 from'./galleryImages/Group2022p2.jpg';
import Group2022p3 from'./galleryImages/Group2022p3.jpg';
import Group2023p1 from'./galleryImages/Group2023p1.jpg';
import Group2023p2 from'./galleryImages/Group2023p2.jpg';
import Group2023p3 from'./galleryImages/Group2023p3.jpg';
import Group2023p4 from'./galleryImages/Group2023p4.jpg';
import Group2023p5 from'./galleryImages/Group2023p5.jpg';
import Group2023p6 from'./galleryImages/Group2023p6.jpg';
import Group2024p1 from'./galleryImages/Group2024p1.jpg';
import Group2024p2 from'./galleryImages/Group2024p2.jpg';
import Group2024p3 from'./galleryImages/Group2024p3.jpg';
import Group2024p4 from'./galleryImages/Group2024p4.jpg';
import Group2024p5 from'./galleryImages/Group2024p5.jpg';
import Group2024p6 from'./galleryImages/Group2024p6.jpg';

const GallerySection = ({ year, imageNum }) => {
    const [openYear, setOpenYear] = useState(false);

    const toggleOpen = () => {
        setOpenYear(!openYear)
    }

    const [img1Src, setImg1Src] = useState(Group2008p1)
    const [img2Src, setImg2Src] = useState(Group2008p1)
    const [img3Src, setImg3Src] = useState(Group2008p1)
    const [img4Src, setImg4Src] = useState(Group2008p1)
    const [img5Src, setImg5Src] = useState(Group2008p1)
    const [img6Src, setImg6Src] = useState(Group2008p1)

    const setImages = (year) => {
        if (year == 2009) {
            setImg1Src(Group2009p2)
        }
        if (year == 2011) {
            setImg1Src(Group2011p1)
            setImg2Src(Group2011p2)
            setImg3Src(Group2011p3)
        }
        if (year == 2012) {
            setImg1Src(Group2012p1)
            setImg2Src(Group2012p2)
            setImg3Src(Group2012p3)
        }
        if (year == 2014) {
            setImg1Src(Group2014p1)
            setImg2Src(Group2014p2)
        }
        if (year == 2016) {
            setImg1Src(Group2016p1)
            setImg2Src(Group2016p2)
            setImg3Src(Group2016p3)
        }
        if (year == 2018) {
            setImg1Src(Group2018p1)
            setImg2Src(Group2018p2)
            setImg3Src(Group2018p3)
        }
        if (year == 2019) {
            setImg1Src(Group2019p1)
            setImg2Src(Group2019p2)
        }
        if (year == 2020) {
            setImg1Src(Group2020p1)
            setImg2Src(Group2020p3)
            setImg3Src(Group2020p4)
        }
        if (year == 2021) {
            setImg1Src(Group2021p1)
            setImg2Src(Group2021p2)
            setImg3Src(Group2021p3)
        }
        if (year == 2022) {
            setImg1Src(Group2022p1)
            setImg2Src(Group2022p2)
            setImg3Src(Group2022p3)
        }
        if (year == 2023) {
            setImg1Src(Group2023p1)
            setImg2Src(Group2023p2)
            setImg3Src(Group2023p3)
            setImg4Src(Group2023p4)
            setImg5Src(Group2023p5)
            setImg6Src(Group2023p6)
        }
        if (year == 2024) {
            setImg1Src(Group2024p1)
            setImg2Src(Group2024p2)
            setImg3Src(Group2024p3)
            setImg4Src(Group2024p4)
            setImg5Src(Group2024p5)
            setImg6Src(Group2024p6)
        }
    }

    useEffect(() => {
        setImages(year)
    }, [])

    return (
        <div class="yearDivider">
            <button onClick={toggleOpen} class="yearButton">{year}</button>
            {openYear && (
                <div class="galleryDiv">
                    <img src={img1Src} class="galleryImg" />
                    {imageNum > 1 &&
                        <img src={img2Src} class="galleryImg" />
                    }
                    {imageNum > 2 &&
                        <img src={img3Src} class="galleryImg" />
                    }
                    {imageNum > 3 &&
                        <img src={img4Src} class="galleryImg" />
                    }
                    {imageNum > 4 &&
                        <img src={img5Src} class="galleryImg" />
                    }
                    {imageNum > 5 &&
                        <img src={img6Src} class="galleryImg" />
                    }
                </div>
            )}
        </div>
    )
};

export default GallerySection